import React from "react"
import { SearchContextProvider } from "../components/SearchContextProvider"
import { graphql, navigate } from "gatsby"
import { useLocation } from "@reach/router";
import { head } from 'lodash';
import "../components/layout.css"

type WorkshopType = {
  id: string
  data: {
    Name: string
    Url: string
  }
}

type WorkshopPageProps = {
  data: {
    workshops: { nodes: WorkshopType[] }
  }
}

function WorkshopPage(props: WorkshopPageProps) {

  let error = false
  const location = useLocation()
  const urlSearchParameters = new URLSearchParams(location.search)

  const workshop = head(props.data?.workshops.nodes.filter(w => w.data.Name == urlSearchParameters.get("id")))

  if (workshop) {
    localStorage.setItem("formUrl", workshop.data.Url)
    navigate("/")
  } else {
    error = true
  }

  return (
    <SearchContextProvider>
      <div className="bg-beige h-screen font-sans text-primary text-center">
        <div className={"fixed top-1/2 left-1/2 " }>
          { error && "Erreur de configuration de l'atelier"}
        </div>
      </div>
    </SearchContextProvider>
  )
}

export const query = graphql`
  query WorkshopQuery {
    workshops: allAirtable(
      filter: { table: { eq: "Workshop" }
    }) {
      nodes {
        id
        data {
          Name
          Url
        }
      }
    }
  }
`

export default WorkshopPage
